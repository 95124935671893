import { Component } from '@angular/core';

@Component({
  selector: 'app-postulate-proyect',
  templateUrl: './postulate-proyect.component.html',
  styleUrls: ['./postulate-proyect.component.scss']
})
export class PostulateProyectComponent {

}

export const environment = {
  production: false,
  api_base: 'https://test.aleph.work/cms/api/public/',
  api_tienda: 'https://test.aleph.work/api/',
  // api_fibras: 'https://fibras-dev.eastus.cloudapp.azure.com/backend/',
  // api_fibras_old: 'https://fibras-prod-app-001.eastus.cloudapp.azure.com/backend/',
  api_fibras: 'https://plataformafibras-api-test.azurewebsites.net/',

  tenant: 'fibras',
  img_base: 'https://test.aleph.work',

  idStore: 125,
  // url_sincronia_old: 'http://fibras-dev.eastus.cloudapp.azure.com/view/homePage?',
  url_sincronia: 'https://plataformafibras-test.azureedge.net/view/homePage?',

  api_local: 'http://localhost:3000/backend/',

  keycloak_url: 'https://test.aleph.work/auth',
  keycloak_realm: 'fibras',
  keycloak_clientId: 'cms',
  keycloak_idpHint: 'fibras-oidc',

  // production: false,
  // version_1: 'v1/',
  // login: 'https://auth-aleph.quanam.com/auth/realms/sistemab/account',
  // ateneos: 'https://ateneo-aleph.quanam.com/ateneos/consejo-empresarial-b/home',
  // leybic: 'https://www.leybic.com/',
  // marketplace: 'https://prod-aleph.quanam.com/marketplace',
  // consejo_b: 'https://consejoempresarialb.org/',
  //
  // captchaKey: '6LdqTV4oAAAAABaaYPtSEDg3J8dpMX4xPZ-4wfdX'
};

import { Component } from '@angular/core';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss'],
})
export class MembersComponent {
  boardOfDirectors = [
    { key: 'Presidente', value: 'Juan Ignacio Machado' },
    { key: 'Vicepresidenta', value: 'Ximena Sommer' },
    { key: 'Secretaria', value: 'Alejandra Zepedeo' },
    { key: 'Tesorero', value: 'Guillermo Fork' },
    { key: 'Prosecretario', value: 'María Castello' },
  ];

  substitutes: string[] = [
    'Magalí Fernández',
    'Mariana Silvera',
    'Aníbal Banquero',
    'Ivelise Reinaldo',
    'Hoyver Villasmil',
  ];
  trustee: string[] = ['Pablo Nessi'];
  sustitute: string[] = ['Juan Valsechi'];
  gerente_op: string[] = ['Leonardo Schmittner'];
  founders: string[] = [
    'Agustina Codazzi',
    'Alejandra Zepedeo',
    'Alejo del Castillo',
    'Analia Semblat',
    'Aníbal Banquero',
    'Diego Sastre',
    'Dinorah Margounato',
    'Fátima Otormín',
    'Federico Rodriguez',
    'Federico Romano',
    'Gerardo Cuneo',
    'Gonzalo Sobral',
    'Guillermo Chanes',
    'Hugo Losa',
    'Jaime Saavedra',
    'Javier Perrachione',
    'Juan Ignacio Machado',
    'Juan Pablo Diaz Magariños',
    'Leandro Castelluccio',
    'Luis Benia',
    'Magali Fernández',
    'Marcos Sarasola',
    'María Castelló',
    'Mariana Silvera',
    'Mercedes Comas',
    'Mikaela Pisani',
    'Natalia Martinez',
    'Rosina Gonzalez',
    'Silvia Vila',
    'Vera Babat',
    'Ximena Sommer',
  ];
  foundingCompanies: string[] = [
    'ABSTRACTA',
    'ÁNIMA',
    'ASFM',
    'CES',
    'DOCTARI',
    'EDHUCA',
    'IDATHA',
    'IMPROVE',
    'KRAKEN',
    'MÁS VIDA',
    'NAXONLABS',
    'PYXIS',
    'SACAPUNTAS',
    'UNI-TE',
  ];
}

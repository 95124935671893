import { HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { AboutUsComponent } from './modules/aboutus/aboutus.component';
import { PartnerDialogComponent } from './modules/aboutus/component/partner-dialog/partner-dialog.component';
import { AlliancesComponent } from './modules/alliances/alliances.component';
import { CommunitiesComponent } from './modules/communities/communities.component';
import { FormularioComponent } from './modules/formulario/formulario.component';
import { CarrouselOneComponent } from './modules/home/component/carrousel-one/carrousel-one.component';
import { CarrouselTwoComponent } from './modules/home/component/carrousel-two/carrousel-two.component';
import { MembersComponent } from './modules/home/component/members/members.component';
import { PropositoComponent } from './modules/home/component/proposito/proposito.component';
import { ProyectosYProgramasComponent } from './modules/home/component/proyectos-y-programas/proyectos-y-programas.component';
import { HomeComponent } from './modules/home/home.component';
import { MarketComponent } from './modules/market/market.component';
import { OdsComponent } from './modules/ods/ods.component';
import { ProyectsComponent } from './modules/proyects/proyects.component';

import { CategoriaFilterPipe } from './categoria-filter.pipe';
import { CategoriaImgFilterPipe } from './categoria-img-filter.pipe';

import { LightgalleryModule } from 'lightgallery/angular';
import { GalleryModule } from 'ng-gallery';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { EmpresasComponent } from './modules/aboutus/empresas/empresas.component';
import { PersonasComponent } from './modules/aboutus/personas/personas.component';
import { ComisionesComponent } from './modules/comisiones/comisiones.component';
import { ComisionDetailComponent } from './modules/comisiones/component/comision-detail/comision-detail.component';
import { CommitmentsComponent } from './modules/commitments/commitments.component';
import { CommitmentDetailComponent } from './modules/commitments/component/commitment-detail/commitment-detail.component';
import { ImpactTradeAgendaComponent } from './modules/communities/impact-trade/component/content/agenda/agenda.component';
import { ImpactTradeCompromisosComponent } from './modules/communities/impact-trade/component/content/compromisos/compromisos.component';
import { ImpactTradeContentComponent } from './modules/communities/impact-trade/component/content/content.component';
import { ImpactTradeFotosComponent } from './modules/communities/impact-trade/component/content/fotos/fotos.component';
import { ViewerComponent } from './modules/communities/impact-trade/component/content/fotos/viewer/viewer.component';
import { IniciativaDetailComponent } from './modules/communities/impact-trade/component/content/iniciativas/details/iniciativa-detail.component';
import { ImpactTradeIniciativasComponent } from './modules/communities/impact-trade/component/content/iniciativas/iniciativas.component';
import { ImpactTradeParticipantesComponent } from './modules/communities/impact-trade/component/content/participantes/participantes.component';
import { ImpactTradeHeaderComponent } from './modules/communities/impact-trade/component/header/header.component';
import { ImpactTradeSidebarComponent } from './modules/communities/impact-trade/component/sidebar/sidebar.component';
import { ImpactTradeComponent } from './modules/communities/impact-trade/impact-trade.component';
import { FutureEventsComponent } from './modules/events/component/future-events/future-events.component';
import { NewsDetailComponent } from './modules/events/component/news-detail.component';
import { PastEventsComponent } from './modules/events/component/past-events/past-events.component';
import { NewsComponent } from './modules/events/news.component';
import { FeaturedComponent } from './modules/home/component/featured/featured.component';
import { InvoicesPage } from './modules/invoices/invoices.page';
import { PaymentsPage } from './modules/payments/payments.page';
import { PlataformComponent } from './modules/plataform/plataform.component';
import { ProyectDetailComponent } from './modules/proyects/component/proyect-detail.component';
import { ThankYouComponent } from './modules/thank-you/thank-you.component';
import { UserPage } from './modules/user/user.page';
import { SafeHtmlPipe } from './shared/pipes/safehtml.pipe';
import { CommunitiesCommitmentsComponent } from './modules/commitments/communities/communities.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SincroniaComponent } from './modules/sincronia/sincronia.component';
import { CommunityPanelComponent } from './modules/communities/community-panel/community-panel.component';
import { CommunityPanelSidebarComponent } from './modules/communities/community-panel/components/sidebar/sidebar.component';
import { CommunityPanelAgendaComponent } from './modules/communities/community-panel/components/panel/agenda/agenda.component';
import { CommunityPanelCompromisosComponent } from './modules/communities/community-panel/components/panel/compromisos/compromisos.component';
import { CommunityPanelContentComponent } from './modules/communities/community-panel/components/panel/content.component';
import { CommunityPanelFotosComponent } from './modules/communities/community-panel/components/panel/fotos/fotos.component';
import { CommunityPanelHeaderComponent } from './modules/communities/community-panel/components/header/header.component';
import { CommunityPanelIniciativasComponent } from './modules/communities/community-panel/components/panel/iniciativas/iniciativas.component';
import { CommunityPanelParticipantesComponent } from './modules/communities/community-panel/components/panel/participantes/participantes.component';
import { SvgIconComponent } from './modules/commitments/svg-icon/svg-icon.component';
import { CommunityCommitmentsComponent } from './modules/commitments/communities/community/community.component';
import { IAResponsableCommunity } from './modules/communities/ia-responsable/ia-responsable.component';
import { IAResponsableCommunityDataService } from './modules/communities/ia-responsable/ia-responsable.service';
import { CommunityDataAbstractService } from './modules/communities/community-panel/community-data';
import { ParticipanteDetailComponent } from './modules/communities/community-panel/components/panel/participantes/details/participante-detail.component';
import { CommitmentListComponent } from './modules/commitments/component/commitment-list/commitment-list.component';
import { ImpactTradeCommunity } from './modules/communities/impact-trade-v2/impact-trade.component';
import { PostulateProyectComponent } from './modules/proyects/postulate/postulate-proyect/postulate-proyect.component';
function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloak_url,
        realm: environment.keycloak_realm,
        clientId: environment.keycloak_clientId,
      },
      initOptions: {
        onLoad: 'check-sso',
        checkLoginIframe: false,
        scope: 'openid',
        // silentCheckSsoRedirectUri:
        //   window.location.origin + '/assets/silent-check-sso.html'
      },
      // shouldAddToken: (request) => {
      //   const { method, url } = request;

      //   const isGetRequest = 'GET' === method.toUpperCase();
      //   const acceptablePaths = ['/assets/'];
      //   const isAcceptablePathMatch = acceptablePaths.some((path) =>
      //     url.includes(path)
      //   );

      //   return !(isGetRequest && isAcceptablePathMatch);
      // },
    });
}

@NgModule({
  declarations: [
    AppComponent,
    CarrouselOneComponent,
    CarrouselTwoComponent,
    ProyectosYProgramasComponent,
    FormularioComponent,
    AboutUsComponent,
    PropositoComponent,
    MembersComponent,
    ProyectsComponent,
    ProyectDetailComponent,
    HomeComponent,
    PartnerDialogComponent,
    AlliancesComponent,
    CommunitiesComponent,
    OdsComponent,
    MarketComponent,
    NewsComponent,
    NewsDetailComponent,
    FutureEventsComponent,
    PastEventsComponent,
    EmpresasComponent,
    PersonasComponent,
    CarrouselOneComponent,
    FeaturedComponent,
    PlataformComponent,
    PaymentsPage,
    InvoicesPage,
    CategoriaFilterPipe,
    CategoriaImgFilterPipe,
    ThankYouComponent,
    SafeHtmlPipe,
    UserPage,
    ImpactTradeComponent,
    ImpactTradeSidebarComponent,
    ImpactTradeContentComponent,
    ImpactTradeHeaderComponent,
    ImpactTradeParticipantesComponent,
    ImpactTradeAgendaComponent,
    ImpactTradeCompromisosComponent,
    ImpactTradeIniciativasComponent,
    ImpactTradeFotosComponent,
    ViewerComponent,
    // ParticipanteDetailComponent,
    IniciativaDetailComponent,
    ImpactTradeCompromisosComponent,
    CommitmentsComponent,
    CommitmentDetailComponent,
    ComisionesComponent,
    ComisionDetailComponent,
    CommunitiesCommitmentsComponent,
    CommunityCommitmentsComponent,
    SincroniaComponent,
    CommunityPanelComponent,
    CommunityPanelSidebarComponent,
    CommunityPanelAgendaComponent,
    CommunityPanelCompromisosComponent,
    CommunityPanelContentComponent,
    CommunityPanelFotosComponent,
    CommunityPanelHeaderComponent,
    CommunityPanelIniciativasComponent,
    CommunityPanelParticipantesComponent,
    SvgIconComponent,
    IAResponsableCommunity,
    ParticipanteDetailComponent,
    CommitmentListComponent,
    ImpactTradeCommunity,
    PostulateProyectComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    KeycloakAngularModule,
    CommonModule,
    NgxGoogleAnalyticsModule.forRoot('G-SBGYBLXZM8'),
    NgxGoogleAnalyticsRouterModule,
    GalleryModule,
    LightgalleryModule,
    MatTooltipModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

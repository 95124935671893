<div
  class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-2"
>
  <h2>{{ infoCompromisos.title }}</h2>
  <div class="link-crear" *ngIf="infoCompromisos.linkProponer">
    <a [href]="infoCompromisos.linkProponer" target="_blank"
      ><span> Proponer un compromiso!</span><span class="ms-1">&rarr;</span></a
    >
  </div>
</div>
<p>
  {{ infoCompromisos.description }}
</p>

<app-commitment-list [compromisosList]="compromisosList"></app-commitment-list>

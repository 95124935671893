import { Injectable } from '@angular/core';
import {
  CommunityDataAbstractService,
  DataComprimisosType,
  DataIniciativasType,
  DataParticipantesType,
} from '../community-panel/community-data';
import { SelectedComponentType } from '../types';

@Injectable()
export class IAResponsableCommunityDataService extends CommunityDataAbstractService {
  override logoAlt: string = '';
  override logoUrl: string = '';
  protected override nameForCommitmentsApi: string = 'IAResponsable';
  protected override module_prefix: string = 'IAResponsable';
  protected override api_base: string = '';
  protected override title: string = 'Inteligencia Artificial Responsable';
  protected override description: string =
    'Inteligencia Artificial Responsable';
  protected override iniciativas_data: DataIniciativasType = {
    title: 'Iniciativas',
    description:
      'Espacio para proponer y compartir iniciativas que surjan en la comunidad IA Responsable de forma de que otros participantes interesados puedan unir esfuerzos, ideas e información',
    linkProponer: '',
  };
  protected override participantes_data: DataParticipantesType = {
    title: 'Miembros',
    description: 'Miembros de IA Responsable',
  };
  protected override compromisos_data: DataComprimisosType = {
    title: 'Compromisos Comunidad IA Responsable',
    description: '',
    linkProponer: '',
  };
  override sidebarItems: SelectedComponentType[] = [
    { id: 'participantes', desc: 'Miembros' },
    { id: 'iniciativas' },
    { id: 'compromisos' },
  ];
}
